import React from 'react';

function About() {
    return (
        <div>
            <h1>Blogs Page</h1>
            <p>This is the Blogs page.</p>
        </div>
    );
}

export default About;
