import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Photos from './pages/photos';
import Blogs from './pages/blogs';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

function App() {
  return (
      <Router>
          <header>
              <div className="breadcrumbs-container">
                  <Breadcrumbs aria-label="breadcrumb" separator="|" className="breadcrumbs">
                      <Link underline="none" color="inherit" href="/" className="breadcrumbs-tab">
                          Home
                      </Link>
                      <Link underline="none" color="inherit" href="/photos" className="breadcrumbs-tab">
                          Photos
                      </Link>
                      <Link underline="none" color="inherit" href="/blogs" className="breadcrumbs-tab">
                          Blogs
                      </Link>
                      <Link underline="none" color="inherit" href="/about" className="breadcrumbs-tab">
                          About me
                      </Link>
                  </Breadcrumbs>
              </div>
          </header>
          <div>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/photos" element={<Photos />} />
                  <Route path="/blogs" element={<Blogs />} />
              </Routes>
          </div>
          <footer className="footer">
              © 2024 Xuan Yu. All rights reserved.
          </footer>
      </Router>
  );
}

export default App;
